const retrieveCompanyData = [
    {
      name: 'Designation',
      email: '',
      title: 'Designation',
      status: '',
    
    },
    {
      name: 'Company Name',
      email: '',
      title: 'Company Name',
      status: '',
    
    },
    {
      name: 'Company Address',
      email: '',
      title: 'Company Address',
      status: '',
    },
    
  ];

  export default retrieveCompanyData;