
const retrieveSecondData = [ 

{
    name: 'Father  Name',
    email: '',
    title: 'Mother  Name',
    status: '',
    
  },
  {
    name: 'Mobile Number',
    email: '',
    title: 'Mobile Number',
    status: '',
  },
  {
    name: 'Email Id',
    email: '',
    title: 'Email Id',
    status: '',
  },
  {
    name: 'Residential Address	',
    email: '',
    title: 'Residential Address	',
    status: '',

  },
  {
    name: 'Area',
    email: '',
    title: 'Area',
    status: '',
    
  },
  {
    name: 'District',
    email: '',
    title: 'District',
    status: '',
    
  },
  {
    name: 'State',
    email: '',
    title: 'State',
    status: '',
    
  },
  {
    name: 'Country',
    email: '',
    title: 'Country',
    status: '',
  },
  {
    name: 'Pin',
    email: '',
    title: 'Pin',
    status: '',
  },
  {
    name: 'Mother Tongue',
    email: '',
    title: 'Mother Tongue',
    status: '',
    
  },
  {
    name: 'Telephone (R)',
    email: ''	,
    title: 'Telephone (R)',
    status: '',
  },
  {
    name: 'Gross Annual Income (INR)',
    email: '',
    title: 'Gross Annual Income (INR)',
    status: '',
  },
];

export default retrieveSecondData;
  